import { Location } from "./components/location";
import { User } from "./components/user";

function App() {
  return (
    <div className="App">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "500px",
        }}
      >
        <img
          src="/images/logo.svg"
          alt="Placetuner"
          style={{ width: "50%", margin: "auto", display: "block" }}
        />
      </div>
      <User />
      <Location />
    </div>
  );
}

export default App;
