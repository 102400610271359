import { useEffect, useState } from "react";
import SpotifywebApi from "spotify-web-api-js";
import { spotifyLoginUrl, getTokenFromUrl } from "../utils/spotify";
import { useStickyState } from "../utils/stickyState";

export const User = () => {
  const [spotifyToken, setSpotifyToken] = useStickyState(null, "spotifyToken");
  const [spotifyUser, setSpotifyUser] =
    useState<SpotifyApi.CurrentUsersProfileResponse>();

  useEffect(() => {
    if (spotifyToken) {
      console.log("spotifyToken", spotifyToken);
      const spotify = new SpotifywebApi();
      spotify.setAccessToken(spotifyToken);
      spotify.getMe().then((user) => setSpotifyUser(user));
    }
  }, [setSpotifyToken, spotifyToken]);

  useEffect(() => {
    const _spotifyToken = getTokenFromUrl().access_token;
    window.location.hash = "";

    if (_spotifyToken) {
      console.log("Setting Token from hash", _spotifyToken);
      setSpotifyToken(_spotifyToken);
    }
  });

  return spotifyUser ? (
    <div>Connected to Spotify: {spotifyUser?.display_name}</div>
  ) : (
    <a href={spotifyLoginUrl}>Login to Spotify</a>
  );
};
