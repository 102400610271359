const authEndpoint = "https://accounts.spotify.com/authorize";
const redirectUri = "https://placetuner.com";
const clientId = "823cad6c0bcc4e47ae3ebdec5b8158d5";
const scopes = [
  "user-modify-playback-state",
  "user-read-playback-state",
  "user-read-recently-played",
  "user-read-email",
];

export const spotifyLoginUrl = `${authEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join(
  "%20"
)}&response_type=token&show_dialog=true`;

export const getTokenFromUrl = (): Record<string, string> => {
  return window.location.hash
    .substring(1)
    .split("&")
    .reduce((initial: any, item) => {
      let parts: string[] = item.split("=");
      initial[parts[0]] = decodeURIComponent(parts[1]);
      return initial;
    }, {});
};
